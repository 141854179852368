
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { EmpreendimentoService } from "@/core/services/cadastros";
import { EmpresaService } from "@/core/services/compras/EmpresaService";
 
@Component
export default class CfgEmpreendimento extends Vue {
  filter = new Model();

  valid = true; 
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  empreendimentos:any = [];
  empresas:any = [];

  get selecionarTodosEmpreendimentos(){
    return this.filter.empreendimentos.length === this.empreendimentos.length
  }

  get SelecionarAlgunsEmpreendimentos(){
    return this.filter.empreendimentos.length > 0 && !this.selecionarTodosEmpreendimentos
  }

  get iconEmpreendimento(){
    if (this.selecionarTodosEmpreendimentos) return 'mdi-close-box'
    if (this.SelecionarAlgunsEmpreendimentos) return 'mdi-minus-box'
    return 'mdi-checkbox-blank-outline'
  }

  get textEmpreendimento(){
    if (this.selecionarTodosEmpreendimentos) return 'Desmarcar'
    if (this.SelecionarAlgunsEmpreendimentos) return 'Selecionando...'
    return 'Selecionar todos'
  }

  get iconEmpresa(){
    if (this.selecionarTodosEmpresas) return 'mdi-close-box'
    if (this.SelecionarAlgunsEmpresas) return 'mdi-minus-box'
    return 'mdi-checkbox-blank-outline'
  }

  get textEmpresa(){
    if (this.selecionarTodosEmpresas) return 'Desmarcar'
    if (this.SelecionarAlgunsEmpresas) return 'Selecionando...'
    return 'Selecionar todos'
  } 

  SelecionarEmpreendimento() {
    this.$nextTick(() => {
      if (this.selecionarTodosEmpreendimentos) {
        this.filter.empreendimentos = []
      } else {
        this.filter.empreendimentos = this.empreendimentos.slice().map((x:any)=>x.id)
      }
    })
  }

  SelecionarEmpresa(){
    this.$nextTick(() => {
      if (this.selecionarTodosEmpresas) {
        this.filter.empresas = []
      } else {
        this.filter.empresas = this.empresas.slice().map((x:any)=>x.id)
      }
    })
  }

  get selecionarTodosEmpresas(){
    return this.filter.empresas.length === this.empresas.length
  }

  get SelecionarAlgunsEmpresas(){
    return this.filter.empresas.length > 0 && !this.selecionarTodosEmpresas
  }

  mounted() {
    new EmpreendimentoService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id, nome', '').then(
      (res) => {
        this.empreendimentos = res.data.items
      }, 
    );

    new EmpresaService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id, nome, nomeFantasia', '').then(
      (res) => {
        this.empresas = res.data.items
      }, 
    );
  }   

  Visualizar() {
    let routeLink = this.$router.resolve({name: 'RelConfrontante',
      query:{
        empreendimentos: this.filter.empreendimentos ? this.filter.empreendimentos.toString() : "",
        empresas: this.filter.empresas ? this.filter.empresas.toString() : '',
      }
    });
    window.open(routeLink.href, '_blank');
  }
}
class Model{
  empreendimentos = [];
  empresas = [];
}
